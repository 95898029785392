/**
 * 支払いステップ時の状態管理用middleware
 */

import { useParkingState } from '@/composables/store/useParkingStore';
import { usePassState } from '@/composables/store/usePassStore';
import { PAYMENT_STEPS } from '@/features/parking/constants';

export default defineNuxtRouteMiddleware((to, from) => {
  const { setPaymentMethod } = useParkingState();
  const { setSelectedPassPeriod } = usePassState();

  if (
    !from.query.step &&
    from.meta.flow !== 'card-create' &&
    to.query.step === String(PAYMENT_STEPS.SELECT)
  ) {
    // 選択した支払い方法をリセット
    setPaymentMethod(undefined);
    // 選択したパスの期間をリセット
    setSelectedPassPeriod(undefined);
  }
});
